import React, {
  useCallback,
  KeyboardEvent,
  TouchEvent,
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Image } from "./Image";
import "./Viewer.css";

export type ViewerProps = {
  images: Image[];
};
export function Viewer({ images }: ViewerProps) {
  let { route }: { route?: string } = useParams();
  const activeImageIndex = images.findIndex((i) => i.route === route);
  const activeImage = images[activeImageIndex];
  const previousImage =
    activeImageIndex > 0 ? images[activeImageIndex - 1] : null;
  const nextImage =
    activeImageIndex < images.length - 1 ? images[activeImageIndex + 1] : null;
  const previousImageRoute = `/i/${
    images[Math.max(0, activeImageIndex - 1)].route
  }`;
  const nextImageRoute = `/i/${
    images[Math.min(images.length - 1, activeImageIndex + 1)].route
  }`;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (rootRef.current == null || containerRef.current == null) {
      return;
    }
    rootRef.current.focus();
    containerRef.current.style.height = `${window.innerHeight}px`;
  }, [rootRef, containerRef]);

  const navigate = useNavigate();
  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case "Escape":
          navigate("/");
          break;
        case "ArrowLeft":
          navigate(previousImageRoute);
          break;
        case "ArrowRight":
          navigate(nextImageRoute);
          break;
      }
    },
    [previousImageRoute, nextImageRoute, navigate]
  );

  const [xStart, setXStart] = useState(0);
  const [xOffset, setXOffset] = useState(0);
  const [xOffsetStyle, setXOffsetStyle] = useState(`0px`);
  const [isTouching, setIsTouching] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [hasNavigateForwardIntent, setHasNavigateForwardIntent] =
    useState(false);
  const [hasNavigateBackwardIntent, setHasNavigateBackwardIntent] =
    useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [canNavigateForward, setCanNavigateForward] = useState(false);
  const [canNavigateBackward, setCanNavigateBackward] = useState(false);

  const onTouchStart = useCallback(
    (e: TouchEvent): any => {
      setIsTouching(true);
      setXStart(e.touches[0].screenX);
    },
    [setIsTouching, setXStart]
  );
  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      if (!canNavigateBackward && !canNavigateForward) {
        return;
      }
      setXOffset(e.touches[0].screenX - xStart);
      setXOffsetStyle(`${e.touches[0].screenX - xStart}px`);
    },
    [canNavigateForward, canNavigateBackward, setXOffset, xStart]
  );
  const onTouchEnd = useCallback(
    (e: TouchEvent) => {
      setIsTouching(false);
      if (window.visualViewport.scale === 1) {
        setCanNavigateForward(nextImage != null);
        setCanNavigateBackward(previousImage != null);
      } else {
        setCanNavigateForward(false);
        setCanNavigateBackward(false);
      }
    },
    [
      setIsTouching,
      nextImage,
      previousImage,
      setCanNavigateForward,
      setCanNavigateBackward,
    ]
  );

  useEffect(() => {
    if (window.visualViewport.scale > 1 || isTouching) {
      return;
    }

    if (nextImage != null) {
      setCanNavigateForward(true);
    }
    if (previousImage != null) {
      setCanNavigateBackward(true);
    }
  }, [
    isTouching,
    nextImage,
    previousImage,
    setCanNavigateBackward,
    setCanNavigateForward,
  ]);

  useEffect(() => {
    if (!isTouching) {
      if (hasNavigateForwardIntent && !isNavigating) {
        setShouldAnimate(true);
        setXOffsetStyle("calc(-100vw - 2em)");
        setTimeout(() => {
          setShouldAnimate(false);
          setIsNavigating(true);
        }, 300);
      } else if (hasNavigateBackwardIntent && !isNavigating) {
        setShouldAnimate(true);
        setXOffsetStyle("calc(100vw + 2em)");
        setTimeout(() => {
          setShouldAnimate(false);
          setIsNavigating(true);
        }, 300);
      } else if (!isNavigating && xOffset !== 0) {
        setShouldAnimate(true);
        setXOffsetStyle("0px");
        setXOffset(0);
        setTimeout(() => {
          setShouldAnimate(false);
        }, 300);
      }
    }
  }, [
    xOffset,
    isTouching,
    hasNavigateForwardIntent,
    hasNavigateBackwardIntent,
    setXOffsetStyle,
    setIsNavigating,
    isNavigating,
    setShouldAnimate,
  ]);

  useLayoutEffect(() => {
    if (isNavigating) {
      if (hasNavigateForwardIntent) {
        setXOffset(0);
        setHasNavigateForwardIntent(false);
        navigate(nextImageRoute);
        setXOffsetStyle("0px");
        setIsNavigating(false);
      } else if (hasNavigateBackwardIntent) {
        setXOffset(0);
        setHasNavigateBackwardIntent(false);
        navigate(previousImageRoute);
        setXOffsetStyle("0px");
        setIsNavigating(false);
      }
    }
  }, [
    isNavigating,
    hasNavigateBackwardIntent,
    hasNavigateForwardIntent,
    navigate,
    nextImageRoute,
    previousImageRoute,
    setHasNavigateBackwardIntent,
    setHasNavigateForwardIntent,
    setXOffsetStyle,
    setIsNavigating,
    setXOffset,
    setShouldAnimate,
  ]);

  useEffect(() => {
    if (
      xOffset < -100 &&
      activeImageIndex < images.length - 1 &&
      canNavigateForward
    ) {
      setHasNavigateForwardIntent(true);
    } else if (xOffset > 100 && activeImageIndex > 0 && canNavigateBackward) {
      setHasNavigateBackwardIntent(true);
    } else {
      setHasNavigateForwardIntent(false);
      setHasNavigateBackwardIntent(false);
    }
  }, [
    xOffset,
    setHasNavigateBackwardIntent,
    setHasNavigateForwardIntent,
    canNavigateBackward,
    canNavigateForward,
    images.length,
    activeImageIndex,
  ]);

  return (
    <div id="viewer-container" ref={containerRef}>
      <div
        className="viewer-root"
        onKeyDown={onKeyPress}
        tabIndex={1}
        ref={rootRef}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <nav className="click-nav">
          {previousImage == null ? null : (
            <Link to={previousImageRoute}>
              <svg className="chevron" viewBox="1 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </Link>
          )}
        </nav>
        {!canNavigateBackward ? (
          <div className="bumper" />
        ) : (
          <img
            alt={previousImage?.route}
            className="offscreen-image"
            src={previousImage?.src}
            style={{
              transition: shouldAnimate ? `transform 300ms` : undefined,
              transform: `translateX(${xOffsetStyle})`,
            }}
          />
        )}
        <img
          alt={activeImage.route}
          className="viewer-image"
          src={activeImage?.src}
          style={{
            transition: shouldAnimate ? `transform 300ms` : undefined,
            transform:
              canNavigateForward || canNavigateBackward
                ? `translateX(${xOffsetStyle})`
                : undefined,
          }}
        />
        {!canNavigateForward ? (
          <div className="bumper" />
        ) : (
          <img
            alt={nextImage?.route}
            className="offscreen-image"
            src={nextImage?.src}
            style={{
              transition: shouldAnimate ? `transform 300ms` : undefined,
              transform: `translateX(${xOffsetStyle})`,
            }}
          />
        )}
        <nav className="click-nav">
          {nextImage == null ? null : (
            <Link to={nextImageRoute}>
              <svg className="chevron" viewBox="-1 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </Link>
          )}
        </nav>
      </div>
      <div id="close-button">
        <Link id="close-link" to="/">
          <svg id="close-cross" width="24px" height="24px" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </Link>
      </div>
    </div>
  );
}
