import React from "react";
import "./App.css";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { Gallery } from "./Gallery";
import { Viewer } from "./Viewer";

function App() {
  const prompts = [
    "crystal",
    "suit",
    "vessel",
    "knot",
    "raven",
    "spirit",
    "fan",
    "watch",
    "pressure",
    "pick",
    "sour",
    "stuck",
    "roof",
    "tick",
    "helmet",
    "compass",
    "collide",
    "moon",
    "loop",
    "sprout",
    "fuzzy",
    "open",
    "leak",
    "extinct",
    "splat",
    "connect",
    "spark",
    "crispy",
    "patch",
    // "slither",
    // "risk",
  ];
  const images = prompts.map((prompt, index) => ({
    route: prompt,
    src: `/images/inktober${(index + 1)
      .toString()
      .padStart(2, "0")}${prompt}.jpg`,
  }));
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Gallery images={images} />} />
        <Route path="i/:route" element={<Viewer images={images} />} />
      </Routes>
    </Router>
  );
}

export default App;
