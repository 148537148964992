import React from "react";
import { Link } from "react-router-dom";
import { Image } from "./Image";

export type GalleryProps = {
  images: Image[];
};
export function Gallery({ images }: GalleryProps) {
  return (
    <div id="gallery-grid">
      <div id="title">
        <a
          aria-label="Red Velvet Panda's tumblr"
          href="https://red-velvet-panda.tumblr.com/"
        >
          <img id="profile-pic" src="/images/profile.jpg" alt="Frog Lady" />
        </a>
        <div id="name">
          <h1>
            <a href="https://red-velvet-panda.tumblr.com/">Red Velvet Panda</a>
          </h1>
          <h2>Inktober 2021</h2>
        </div>
      </div>
      {images.map((e) => (
        <div className="galleryItem" key={e.route}>
          <Link to={`i/${e.route}`}>
            <label className="itemLabel">{e.route}</label>
            <img alt={e.route} className="galleryImage" src={e.src} />
          </Link>
        </div>
      ))}
    </div>
  );
}
